import React from 'react';
import { Container } from 'reactstrap';
import MainCarousel from '../components/carousel';
import SEO from '../components/seo';
import '../styles/theme.scss';

const IndexPage = () => (
  <>
    <Container fluid className="HomePageContainer">
      <SEO title="Jimmy Truong | Fullstack Developer" />
      <MainCarousel />
    </Container>
  </>
);
export default IndexPage;
